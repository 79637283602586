import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: 'https://dev-api.clyde.team/api/v1'
});

api.interceptors.request.use(
    (config) => {
        
      const accessToken = Cookies.get("accessToken");
  
      try {
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      } catch (err) {
        console.error("[_axios.interceptors.request] config : " + err.message);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      try {
        const response = await axios.post('https://dev-api.clyde.team/api/v1', {
          refreshToken: refreshToken
        });
        if (response.status === 200) {
          localStorage.setItem('accessToken', response.data.accessToken);
          return axios(originalRequest);
        }
      } catch (refreshError) {
        // Handle refresh token request error
        console.error('Error refreshing token:', refreshError);
        // Redirect to login or do something else
      }
    }
    return Promise.reject(error);
  }
);

export default api;
