import React, { useState, useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setToken] = useState(localStorage.getItem("accessToken") || "");
  const navigate = useNavigate();

  const loginAction = async (data) => {
    try {
     const response = await fetch("https://dev-api.clyde.team/api/v1/login", {
        method: "POST",
        headers: {
          "Authorization" : "Bearer " + accessToken,
          'Accept': 'application/json',
          "Content-Type" : "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.data) {
        setUser(res.data.user);
        setToken(res.token);
        localStorage.setItem("token", res.token);
        navigate("/admin");
        return true;
      }
      throw new Error(res.message);
    } catch (error) {
      alert(error.message);
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("token");
    navigate("/auth/sign-in");
  };

  return (
    <AuthContext.Provider value={{ user, accessToken, loginAction, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};