
import Checkbox from "components/checkbox";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import api from "./Instance";

export default function SignIn() {


const [credentials, setCredentials] = useState({
      identifier: "",
      password: "",
    });

const handleChange = (e) => {
  setCredentials({
    ...credentials,
    [e.target.name]: e.target.value,
  });
}
const navigate = useNavigate();

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const response = await api.post('https://dev-api.clyde.team/api/v1/login', credentials, {withCredentials: true}) ;
    const data = response.data;
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.data.jwtTokens.accessToken}`;
  localStorage.setItem('accessToken', data.data.jwtTokens.accessToken);
  localStorage.setItem('refreshToken', data.data.jwtTokens.refreshToken);
  
  
  
  navigate("/admin");

} catch (error) {
  console.error(error);
}}


  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>

        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your identifier and password to sign in!
        </p>



       <form onSubmit={handleSubmit}>
        <input
          value={credentials.identifier}
          variant="auth"
          extra="mb-3"
          label="Identifier"
          placeholder="enter your identifier"
          id="identifier"
          type="text"
          name="identifier"
          onChange={handleChange}
        />

        <input
        value={credentials.password}
          variant="auth"
          extra="mb-3"
          label="Password"
          placeholder="password"
          id="password"
          type="password"
          name="password"
          onChange={handleChange}
        />

        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <button type="submit" className="linear mt-2 w-full rounded-xl bg-brand-400 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>
        </form>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
  }